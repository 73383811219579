import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'

import breakpoint from '@utils/breakpoint'

import Icon from '@objects/icon'
import Button from '@objects/button'

const StyledDownloadIcon = styled(Icon).attrs({
  name: 'Download',
  className: 'text-xl text-red',
})`
  margin-left: 4px;
  margin-bottom: 4px;
`

const StyledImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors['gray-light']?.default};
`

const StyledMediaItem = styled.a.attrs({
  className: 'cursor-pointer',
})`
  .gatsby-image-wrapper {
    ${tw`
      min-w-full transition-transform duration-700 ease-in-out
    `}
    transform: scale(1);
  }

  &:hover {
    text-decoration: none;
  }

  width: 242px;

  &:nth-of-type(n + 3) {
    margin-top: ${({ theme }) => theme.spacing['9']};
  }

  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-left: 16px;

  ${breakpoint('md')`
    max-width: 242px;

    &:hover {
        .gatsby-image-wrapper {
          transform: scale(1.05);
        }
    }

    &:nth-of-type(2n + 1) {
      margin-left: 16px;
    }

    &:nth-of-type(n + 3) {
      margin-top: 0;
    }
  `}
`

function MediaGallery({
  className,
  files,
  showmore,
  initialshow,
  loadpershowmore,
}) {
  const [loadnumber, setLoadnumber] = useState(initialshow || 12)
  const [hideloadmore, setHideloadmore] = useState(false)
  const intl = useIntl()

  function renderTeaserImage(image) {
    return (
      <Img
        fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
        alt={image.description}
        backgroundColor="grey"
        role="img"
      />
    )
  }

  function renderMediaList() {
    let list = []

    for (let i = 0; i < files.length; i++) {
      const item = files[i]
      list.push(
        <StyledMediaItem
          key={i}
          data-testid="listitem"
          target="_blank"
          rel="noreferrer"
          href={item.image?.file.url || item.video?.file.url}
          role={!!item.video ? 'video' : 'img'}
        >
          <div>
            <StyledImageWrapper
              className="overflow-hidden max-h-full border border-grey-light"
              aria-label={item.thumbnail?.title}
            >
              {item.image?.fluid && renderTeaserImage(item.image)}
              {item.thumbnail?.fluid && renderTeaserImage(item.thumbnail)}
            </StyledImageWrapper>
            {!!item.copy && <div>{item.copy}</div>}
          </div>
          <div className="mt-2 flex flex-row justify-between items-center">
            <p className="font-normal text-sm text-black-650" role="paragraph">
              {item.createdAt}
            </p>
            <p className="font-medium text-red">
              <FormattedMessage id="download" />
              <StyledDownloadIcon />
            </p>
          </div>
        </StyledMediaItem>
      )

      // break if showmore
      if (showmore && list.length === loadnumber) {
        break
      }
    }
    if (showmore && !hideloadmore && list.length === files.length) {
      setHideloadmore(true)
    }
    return list
  }

  function onLoadMoreClick() {
    setLoadnumber(loadnumber + loadpershowmore)
  }

  const numberOfFiles = files.length - loadnumber
  return (
    <>
      <div
        className={clsx(
          className,
          'w-full flex flex-row justify-start flex-wrap relative gap-2'
        )}
        role="group"
      >
        {renderMediaList()}
      </div>
      {showmore && !hideloadmore && (
        <div
          className="text-center mt-9"
          aria-label={intl.formatMessage({ id: 'button.loadmore' })}
        >
          <Button showmore onClick={onLoadMoreClick}>
            <FormattedMessage id="button.loadmore" />
          </Button>
          <p
            className="mt-3 font-normal text-black-650"
            role="paragraph"
            aria-label={`${numberOfFiles} ${intl.formatMessage({
              id: 'downloads.elements',
            })}`}
          >
            ({numberOfFiles} <FormattedMessage id="downloads.elements" />)
          </p>
        </div>
      )}
    </>
  )
}

MediaGallery.propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      image: PropTypes.shape({
        file: PropTypes.object,
        fluid: PropTypes.object,
        description: PropTypes.string,
      }),
      thumbnail: PropTypes.object,
      video: PropTypes.shape({
        file: PropTypes.object,
      }),
      copy: PropTypes.string,
    })
  ).isRequired,
  showmore: PropTypes.bool,
  initialshow: PropTypes.number,
  loadpershowmore: PropTypes.number,
}

MediaGallery.defaultProps = {
  files: [
    {
      id: '1',
      title: 'missing title',
      copy: 'missing copy',
      image: null,
      video: null,
    },
  ],
}

export default MediaGallery
